import InputText from '../InputText';
import { DistanceAddress, GeocodedAddress, LatLng } from '~/lib/model';
import { useState } from 'react';
import { useKeyboardHighlight } from '~/components/hooks/useKeyboardHighlight';
import { State, useHookstate } from '@hookstate/core';
import AutoComplete from '~/components/interactive/AutoComplete';
import { v4 } from 'uuid';
import IconLoading from '~/components/icons/streamline/line/IconLoading';
import IconSolidSearch from '~/components/icons/streamline/solid/IconSolidSearch';
import IconMarker from '~/components/icons/streamline/line/IconMarker';
import { useTenantLocation } from '~/tenants/common/TenantContextProvider';
import { GeoDistance } from '~/routes/api+/geo.distance';
import { DateTime } from 'luxon';

export interface InputAddressProps {
  address?: GeocodedAddress;
  focus?: boolean;
  coords?: LatLng;
  onChange: (address: DistanceAddress | null) => Promise<void> | void;
  onCustom?: () => void;
  distance?: GeocodedAddress;
  start?: DateTime;
}

interface FormAddressState {
  loading: boolean;
  disableInput: boolean;
  focus: boolean;
  suggestions: string[] | null;
  token: string;
  input: string | null;
  geocoded?: GeocodedAddress;
}

function Input(props: { focus?: boolean; coords?: LatLng; state: State<FormAddressState> }) {
  const [autoCompleting, setAutoCompleting] = useState(false);
  const [focused, setFocused] = useState(false);

  if (props.state.geocoded.get()) {
    return <></>;
  }

  const doSearch = (query: string) => {
    if (autoCompleting) {
      // prevent from firing a ton of calls at once
      return;
    }

    props.state.merge({ loading: true });

    setAutoCompleting(true);

    fetch('/api/geo/autocomplete', {
      body: JSON.stringify({ address: query, token: props.state.token.get(), coords: props.coords }),
      method: 'POST',
    })
      .then((r) => r.json<string[]>())
      .then((resp) => {
        // allow another to fire off
        setAutoCompleting(false);

        const input = props.state.input.get();

        if (!input) {
          // if user cleared the box, do not show any results
          props.state.merge({ loading: false, suggestions: null });

          return;
        }

        props.state.merge({ loading: input === query, suggestions: resp });

        if (input !== query) {
          // make sure the search reflects the current input
          doSearch(input);
        }
      })
      .catch(() => {
        setAutoCompleting(false);
      });
  };

  return (
    <>
      <InputText
        icon={autoCompleting ? <IconLoading /> : <IconSolidSearch />}
        autoFocus={props.focus}
        disabled={props.state.disableInput.get()}
        onBlur={() => {
          setFocused(false);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        placeholder="Search by entering a street address (ex: 1234 Lake Shore Drive)"
        onChange={(value) => {
          if (!value) {
            props.state.merge({ input: null, suggestions: null });

            return;
          }

          props.state.merge({ input: value });

          doSearch(value);
        }}
        value={props.state.input.get() || ''}
      />
    </>
  );
}

const USE_CUSTOM = 'Use a custom address';

function Suggestions(props: {
  state: State<FormAddressState>;
  onSelect: (address: DistanceAddress) => void;
  onCustom?: () => void;
  distance?: GeocodedAddress;
  start?: DateTime;
}) {
  const [selected, setSelected] = useState<string | null>(null);
  const [geocoding, setGeocoding] = useState(false);

  const setDisableInput = (disabled: boolean, extra?: Partial<FormAddressState>) => {
    props.state.merge({ disableInput: disabled, ...extra });
  };

  const clearSuggestionState = () => {
    setSelected(null);
    setGeocoding(false);
  };

  const doCancel = (extra?: Partial<FormAddressState>) => {
    setDisableInput(false, extra);

    clearSuggestionState();
  };

  const onSelect = async (line1: string, onFail: () => void) => {
    setDisableInput(true);
    setSelected(line1);
    setGeocoding(true);

    let address: GeocodedAddress | null = null;

    try {
      address = await fetch('/api/geo/geocode', {
        body: JSON.stringify({ address: line1 }),
        method: 'POST',
      }).then((r) => r.json<DistanceAddress | null>());
    } catch (ex) {
      console.error(ex);
    }

    if (!address) {
      onFail();

      doCancel();

      return;
    }

    let distance: number | null = null;
    let time: number | undefined;

    if (props.distance) {
      try {
        const { meters, seconds } = await fetch('/api/geo/distance', {
          body: JSON.stringify({
            to: address,
            from: props.distance,
            start: props.start?.toJSDate(),
          }),
          method: 'POST',
        }).then(async (r) => r.json<GeoDistance>());
        distance = meters;
        time = seconds;
      } catch (ex) {
        console.error(ex);
      }

      if (!distance || !time) {
        onFail();

        doCancel();

        return;
      }
    }

    setDisableInput(false);

    props.onSelect({ ...address, distance, time });

    clearSuggestionState();
  };

  const state = props.state.value;

  const options = Array.from(state.suggestions || []);

  if (props.onCustom) {
    options.push(USE_CUSTOM);
  }

  const { reset, highlighted } = useKeyboardHighlight({
    options,
    enabled: !geocoding && Boolean(state.input),
    onSelect({ value, reset }) {
      if (value === USE_CUSTOM) {
        props.onCustom?.();
        return;
      }

      onSelect(value, reset).catch((e) => console.error(e));
    },
    onEscape() {
      doCancel({
        input: null,
        loading: false,
        suggestions: null,
      });
    },
  });

  if (!state.suggestions || (!props.onCustom && state.suggestions.length == 0) || state.geocoded) {
    return <></>;
  }

  return (
    <div
      className="z-10 bg-theme-content rounded-lg shadow-lg -mt-8"
      onMouseEnter={() => {
        // hide keyboard highlighting if mouse enters
        reset();
      }}
    >
      {options.map((a, index) => {
        const [street, ...rest] = a.split(',');

        if (a === USE_CUSTOM) return null;

        return (
          <AutoComplete
            key={a}
            onMouseEnter={reset}
            icon={<IconMarker />}
            loading={geocoding && selected === a ? 'Determining coordinates for address...' : undefined}
            onClick={() => {
              onSelect(a, reset).catch((e) => console.error(e));
            }}
            highlighted={index === highlighted}
            disabled={geocoding && selected !== a}
          >
            <div className="space-x-1">
              <span className="font-medium">{street}</span>
              <span className="color-faint group-highlighted:text-white">{rest.join(',')}</span>
            </div>
          </AutoComplete>
        );
      })}
      {props.onCustom && (
        <AutoComplete
          onMouseEnter={reset}
          icon={<IconMarker />}
          onClick={props.onCustom}
          highlighted={highlighted === options.indexOf(USE_CUSTOM)}
          disabled={geocoding && selected !== USE_CUSTOM}
        >
          <div className="space-x-1">
            <span className="font-bold">Can't find what you are looking for?</span>
            <span className="color-faint group-highlighted:text-white">Enter a custom address</span>
          </div>
        </AutoComplete>
      )}
    </div>
  );
}

export default function InputAddressSearch(props: InputAddressProps) {
  const location = useTenantLocation(true).address;

  const state = useHookstate<FormAddressState>({
    input: props.address?.line1 ?? null,
    token: v4(),
    loading: false,
    focus: !!props.focus,
    disableInput: false,
    suggestions: null,
  });

  return (
    <div className="space-y-1">
      <Input
        focus={props.focus}
        state={state}
        coords={location ? { lat: location.lat, long: location.long } : undefined}
      />
      <Suggestions
        state={state}
        onSelect={props.onChange}
        distance={props.distance}
        start={props.start}
        onCustom={props.onCustom}
      />
    </div>
  );
}
